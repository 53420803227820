import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import styles from './ProfileDropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetUserProfileQuery } from '../../../store/apis/profile';
import Button from '../../../components/Button';
import { FiBookOpen, FiFileText, FiHelpCircle, FiLogOut } from 'react-icons/fi';
import useToggle from '../../../hooks/useToggle';
import { Link, useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import localforage from 'localforage';
import { useDispatch } from 'react-redux';
import { mainApi } from '../../../store/apis';
import { getHeadIfExists, joinExisting } from '../../../utils';
import { socket } from '../../../socket';

const ProfileDropdown = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, open, close] = useToggle();
  const containerRef = useRef(null);

  const { data: me, isLoading, isFetching } = useGetUserProfileQuery();

  const goToProfile = useCallback(() => {
    navigate(appRoutes.profile());
    close();
  }, [close, navigate]);

  const exit = useCallback((e) => {
    e.preventDefault();
    localStorage.removeItem('credentials');
    socket.disconnect();
    dispatch(mainApi.util.resetApiState());
    window.location.href = 'https://5plus.uz';
  }, [dispatch, navigate]);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (containerRef.current && isOpen && !containerRef.current.contains(e.target)) {
        close();
      }
    });
  }, [isOpen, close, open]);

  const profileContainerClasses = useMemo(() => cn({
    [styles.profileContainer]: true,
    [styles.profileContainerVisible]: isOpen,
  }), [isOpen]);

  if (isLoading || isFetching) return null;

  if (!me) return null;

  return (
    <div className={styles.profile}>
      <button type="button" className={styles.profileAvatar} onClick={open}>
        {
          me.photoUrl
            ? <img src={me.photoUrl} alt=""/>
            : joinExisting([getHeadIfExists(me.firstName), getHeadIfExists(me.lastName)], '')
        }
      </button>
      <div className={profileContainerClasses} ref={containerRef}>
        <div className={styles.profileInfo}>
          <div className={styles.profileInfoAvatar}>
            {
              me.photoUrl
                ? <img src={me.photoUrl} alt=""/>
                : joinExisting([getHeadIfExists(me.firstName), getHeadIfExists(me.lastName)], '')
            }
          </div>
          <div className={styles.profileInfoContact}>
            <div className={styles.profileInfoContactName}>{me.fullName}</div>
            <div className={styles.profileInfoContactPhone}>{me.phoneNumber}</div>
          </div>
        </div>

        <div className={styles.profileLink}>
          <Button
            color="secondary"
            fullWidth
            size="small"
            onClick={goToProfile}
          >
            {t('Управление аккаунтом')}
          </Button>
        </div>

        <div className={styles.profileSublinks}>
          <Link to={appRoutes.help()} className={styles.profileSublinksItem} onClick={close}>
            <span className={styles.profileSublinksIcon}>
              <FiHelpCircle />
            </span>
            <span className={styles.profileSublinksText}>
              {t('Помощь')}
            </span>
          </Link>
          <a href="https://5plus.uz/online/wiki/%D0%9F%D0%A3%D0%91%D0%9B%D0%98%D0%A7%D0%9D%D0%90%D0%AF%20%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90%20%D0%BE%D0%B1%20%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D0%B8%20%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%8B%D1%85%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D1%83%D1%81%D0%BB%D1%83%D0%B3" target="_blank" rel="noopener noreferrer" className={styles.profileSublinksItem}>
            <span className={styles.profileSublinksIcon}>
              <FiBookOpen />
            </span>
            <span className={styles.profileSublinksText}>
              {t('Публичная оферта')}
            </span>
          </a>


          {/*<a href="#" target="_blank" rel="noopener noreferrer" className={styles.profileSublinksItem}>*/}
          {/*  <span className={styles.profileSublinksIcon}>*/}
          {/*    <FiFileText />*/}
          {/*  </span>*/}
          {/*  <span className={styles.profileSublinksText}>*/}
          {/*    {t('Политика конфиденциальности')}*/}
          {/*  </span>*/}
          {/*</a>*/}

          <a href="#" onClick={exit} className={styles.profileSublinksItem}>
            <span className={styles.profileSublinksIcon}>
              <FiLogOut />
            </span>
            <span className={styles.profileSublinksText}>
              {t('Выйти из аккаунта')}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
