import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './Register.module.scss';
import { useForm } from 'react-hook-form';
import Title from '../../../components/Title';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text';
import { Link, useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import PhoneInputController from '../../../components/PhoneInput';
import Button from '../../../components/Button';
import { createDataSchema } from '../../../yup';
import { string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSignupPhoneConfirmationMutation } from '../../../store/apis/auth';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';

const schema = createDataSchema({
  phoneNumber: string().required().min(12),
});
const Register = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const query = useQueryString();

  const [requestPhoneConfirmation, { isLoading, isFetching }] = useSignupPhoneConfirmationMutation();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      await requestPhoneConfirmation(values).unwrap();
      navigate({
        pathname: appRoutes.registerPhoneConfirmation(),
        search: queryString.stringify(query),
      }, { state: values });
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [navigate, requestPhoneConfirmation]);

  return (
    <div className={styles.register}>
      <form onSubmit={submit(handleSubmit)}>
        <div className={styles.registerHeader}>
          <Title level={3}>{t('Регистрация')}</Title>
          <Text level={2} color="secondary">
            {t('Уже есть аккаунт?')}
            {' '}
            <Link
              to={{
                pathname: appRoutes.login(),
                search: queryString.stringify(query),
              }}
            >
              {t('Войдите')}
            </Link>
          </Text>
        </div>
        <div className={styles.registerBody}>
          <PhoneInputController
            name="phoneNumber"
            control={control}
            label={t('Номер телефона')}
            id="phone"
            inputFormat="+### ## #######"
            placeholder="+998 99 1234567"
            mask=" "
          />
          <Button type="submit" fullWidth loading={isLoading || isFetching} className="Button_button__JBBzO_Continue">
            {t('Продолжить')}
          </Button>
        </div>
        <div className={styles.registerFooter}>
          <Text level={3} noPadding>{t('Нажимая кнопку продолжить вы соглашаетесь с')}</Text>
          <Text level={3}>
            <a
              href="https://5plus.uz/online/wiki/%D0%9F%D0%A3%D0%91%D0%9B%D0%98%D0%A7%D0%9D%D0%90%D0%AF%20%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90%20%D0%BE%D0%B1%20%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D0%B8%20%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%8B%D1%85%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D1%83%D1%81%D0%BB%D1%83%D0%B3"
              target="_blank"
            >
              {t('публичной офертой')}
            </a>
          </Text>
        </div>
      </form>
    </div>
  );
};

export default Register;
