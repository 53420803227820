export const externalRoutes = {
  landingTutors: 'https://5plus.uz/online/tutors',
  faq: 'https://5plus.uz/online/faq',
  about: 'https://5plus.uz/online/',
  root: (lang) => {
    switch (lang) {
      case 'ru': return 'https://5plus.uz/online/ru';
      case 'uz': return 'https://5plus.uz/online';
      case 'en': return 'https://5plus.uz/online/en';
      default: return 'https://5plus.uz/online';
    }
  },
}
